import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import adblocker_icon from "./assets/ad-blocker-icon.png";
import visa from "./assets/visa-icon.png";
import mastercard from "./assets/master-card-icon.png";
import payPal from "./assets/paypal-icon.png";
import applePay from "./assets/apple-pay-icon.png";
import americanExpress from "./assets/american-express-icon.png";
import gPay from "./assets/g-pay-icon.png";
import tick_icon from "./assets/tick-icon.png";
import shield from "./assets/shield-check.png";
import lock from "./assets/lock-icon.png";
import OrderSummary from "./OrderSummary";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [selectedOption, setSelectedOption] = useState("");
  const [ShowModal, setShowModal] = useState(false);
  const [plan_rate, setPlanRate] = useState("20");


  const [message, setMessage] = useState(null);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    console.log("Radio Button Clicked");
  };

  const closeModal = () => {
    return setShowModal(false);
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);


  return (
    <>
      <header>
        <img src={adblocker_icon} alt="Ad-block-icon" />
        <h1>
          <span>Ad</span>Block Premium
        </h1>
      </header>
      <section>
        <div id="plan">
          <div className="div_1">
            <div>
              <h2>Choose Your Plan</h2>
            </div>
          </div>
          <hr />
          <div>
            <button
              type="button"
              className="btn btn-primary position-relative shadow-lg rounded"
            >
              <div id="main_btn">
                <img src={tick_icon} alt="" />
                AdBlock Premium + VPN
              </div>

              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning text-dark p-2">
                Save 20%
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
          </div>
          <div className="form-check" id="radio">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              onChange={handleRadioChange}
            />
            <label className="form-check-label" htmlFor="flexRadioDefault2">
              By checking the box below, you agree to the
              <br />
              <span id="terms-condition">
                <a href="/">terms and conditions.</a>
              </span>
            </label>
          </div>
          <hr />
          <p>
            <img src={shield} height="40px" alt="" /> 60-Day money-back
            guarantee
          </p>
        </div>
        <div>
          <div id="payment">
            <div>
              <h2>Payment</h2>
              <hr />
            </div>

            <div>
              <button
                style={{ padding: "13px" }}
                className="btn btn-warning position-relative shadow-lg"
                disabled={!selectedOption}
                onClick={() => setShowModal(true)}
              >
                <img src={lock} height="18px" alt="lock" /> Checkout Now
              </button>
              {ShowModal && <OrderSummary plan_rate={plan_rate} closeModal={closeModal} ShowModal={ShowModal} setShowModal={setShowModal} />}
            </div>
            <div id="payments_icons">
              <img src={visa} alt="visa" />
              <img src={mastercard} alt="Master Card" />
              <img src={payPal} alt=" PayPal" />
              <img src={applePay} alt="Apple Pay" />
              <img src={americanExpress} alt="American Express" />
              <img src={gPay} alt="G Pay" />
            </div>
            <hr />
            <div id="total_amount">
              <h6>AdBlock Premium Total:</h6>
              <h6>${plan_rate}/yr</h6>
            </div>
          </div>
          <div>
            <p>
              Already have premium or contributed before?
              <span> Activate here</span>
            </p>
          </div>
        </div>
      </section>

    </>
  );
}