import React, { useEffect, useState, useMemo } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import cancel from "./assets/cancel-icon.png";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

const OrderSummary = ({ plan_rate, ShowModal, setShowModal }) => {
  const stripe = useStripe();
  const elements = useElements();

  // const [value, setValue] = useState("");
  //   const [email, setEmail] = useState("");
  // const [postalCode, setPostalCode] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  // const options = useMemo(() => countryList().getData(), []);

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const changeHandler = (value) => {
  //   setValue(value);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {

      setMessage("An unexpected error occurred.");
    }
    console.log(plan_rate, "plan_rate");
    setShowModal(false);
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // const handlePostalCodeChange = (event) => {
  //   setPostalCode(event.target.value);
  // };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleClose = () => setShowModal(false);

  // useEffect(() => {
  //   document.body.style.overflowY = "hidden";
  //   return () => {
  //     document.body.style.overflowY = "scroll";`
  //   };
  // }, []);

  return (
    <>
      <Modal show={ShowModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Your Details</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Payment
              </li>
            </ol>
          </nav>
            <div className="section2">
              <div className="title">
                <h3>Order Summary</h3>
              </div>
            </div>
            <p style={{ textAlign: "center", color: "gray" }}>
              We collect this information to help combat fraud, and to keep your
              payment secure.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>    <form className="form" id="payment-form" onSubmit={handleSubmit}>
          <LinkAuthenticationElement
            id="link-authentication-element"
            onChange={(e) => setEmail(e.target.value)}
          />
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
          {/* <div className="form-group">
              <label htmlFor="email">
                Email:<span className="asterisk">*</span>{" "}
              </label>
              <input
                className="input"
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="country">
                Country:<span className="asterisk">*</span>{" "}
              </label>
              <Select
                options={options}
                value={value}
                onChange={changeHandler}
                styles={{ boxShadow: "" }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="postalCode">
                Postal Code:<span className="asterisk">*</span>{" "}
              </label>
              <input
                className="input"
                type="text"
                id="postalCode"
                value={postalCode}
                onChange={handlePostalCodeChange}
                required
              />
            </div> */}
          <div className="total_payment">
            <h4>Total Payment</h4>
            <p>$20.00</p>
          </div>
          <div className="checkbox">
            <input
              className=""
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label>
              Twitch Adblock Inc may send me product updates and offers via
              email. It is possible to opt-out at any time
            </label>
          </div>
          {/* <button className="submit-btn modal-btn" type="submit">
              Continue
            </button> */}

          <button
            disabled={isLoading || !stripe || !elements}
            className="submit-btn modal-btn"
            id="submit"
          >
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay now"
              )}
            </span>
          </button>
          {message && <div id="payment-message">{message}</div>}
        </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

    </>
  );
};

export default OrderSummary;
